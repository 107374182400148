import { Component, OnInit, TemplateRef } from '@angular/core';
import { Export } from '../_models/export';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../_services/auth.service';
import { DocumentService } from '../_services/document.service';
import { DocumentInstruction } from '../_models/documentinstruction';
import { Download } from '../_models/download';
import { DateService } from '../_services/date.service';
import { NominationDocument } from '../_models/nominationdocument';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-export-detail',
  templateUrl: './export-detail.component.html',
  styleUrls: ['./export-detail.component.css']
})
export class ExportDetailComponent implements OnInit {

  export: Export;
  modalRef: BsModalRef;
  exportToDuplicateID: number ;
  //DIModel: any = {};
  NominationModel: any = {};
  fileToDownload: any = {};
  public hasFile = false;
  public filePathToDownload: string;
  public currentTab = 0 ;

  constructor(private exportService: ExportService,
     private alertify: AlertifyService,
     private route: ActivatedRoute,
     private modalService: BsModalService,
     private authService: AuthService,
     private router: Router,
     private documentService: DocumentService,
     private dateService: DateService) { }

  ngOnInit() {
    // this.loadExport();
    this.route.data.subscribe(data => {
      this.export = data['export'];
    });
    console.log(this.export);
    //this.SetProperties();
  }

  openModal(template: TemplateRef<any>, e: any) {
    // console.log(e);
    this.exportToDuplicateID = e;
    this.modalRef = this.modalService.show(template);
  }

  closeConfirmModal() {
    this.modalRef.hide();
  }

  duplicateExport() {
    console.log(this.exportToDuplicateID);
    this.exportService.duplicateExportWithID(this.exportToDuplicateID, this.authService.decodedToken.nameid).subscribe(
      (exports: Export) => {
        this.modalRef.hide();
        this.alertify.success('Export Successfully Duplicated');
        this.router.navigate(['/dash-board']);
      }, error => {
        this.alertify.error('Problem Duplicating Export');
      }
    );
  }

  // loadExport() {
  //   this.exportService.getExport(+this.route.snapshot.params['id']).subscribe((exports: Export) => {
  //     this.export = exports;
  //     console.log(this.export.vesselAgents.name);
  //   }, error => {
  //     this.alertify.error(error);
  //   });
  // }

  generateDocumentInstruction(lifterId: number) {
    this.getNominationValues();
    console.log('Generting Document....');

    this.documentService.generateDocumentInstructionStardeep(lifterId, this.NominationModel).subscribe(
      data => {
        console.log(data);
        saveAs(data, this.NominationModel.cargoNumber + ' DI.docx');
        this.alertify.success('Download Successful');
      }, error => {
        this.alertify.error('Unable to load files');
      }
    );
  }
  generateDPRNominationDocument(lifterId: number) {
    this.getNominationValues();
    console.log(this.NominationModel);

    this.documentService.generateDPRNominationDocument(lifterId, this.NominationModel).subscribe(
      data => {
        console.log(data);
        saveAs(data, this.NominationModel.cargoNumber + ' DPR_Letter.docx');
        this.alertify.success('Download Successful');
      }, error => {
        this.alertify.error('Unable to load files');
      }
    );
  }
  generateNominationDocument(lifterId: number) {
    this.getNominationValues();
    console.log(this.NominationModel);

    this.documentService.generateNominationDocument(lifterId, this.NominationModel).subscribe(
      data => {
        console.log(data);
        saveAs(data, this.NominationModel.cargoNumber + ' DPR_Attachment.docx');
        this.alertify.success('Download Successful');
      }, error => {
        this.alertify.error('Unable to load files');
      }
    );
  }
  generateNNPCNominationDocument(lifterId: number) {
    this.getNominationValues();
    console.log(this.NominationModel);

    this.documentService.generateNNPCNominationDocument(lifterId, this.NominationModel).subscribe(
      data => {
        console.log(data);
        saveAs(data, this.NominationModel.cargoNumber + ' NNPC_Letter.docx');
        this.alertify.success('Download Successful');
      }, error => {
        this.alertify.error('Unable to load files');
      }
    );
  }
  generateRFIDocument(lifterId: number) {
    this.getNominationValues();
    console.log(this.NominationModel);
    this.documentService.generateRFIDocument(lifterId, this.NominationModel).subscribe(
      data => {
        console.log(data);
        saveAs(data, this.NominationModel.cargoNumber + ' RFI.docx');
        this.alertify.success('Download Successful');
      }, error => {
        this.alertify.error('Unable to load files');
      }
    );
  }

  // SetProperties() {

  //   this.DIModel.letterDate = this.dateService.currentDateForDocument();
  //   this.DIModel.dateRange = this.dateService.documentDate(this.export.dateRangeStart)
  //   + '-' + this.dateService.documentDate(this.export.dateRangeEnd);
  //   this.DIModel.vesselName = this.export.vessel.name;
  //   this.DIModel.volume = this.export.norminatedCargo;
  //   this.DIModel.NXPNumber = this.export.nxpNumber;
  //   this.DIModel.destination = this.export.destinations.name;
  //   this.DIModel.consignor = this.export.consignor.name;
  //   this.DIModel.consignee = this.export.consignees.name;
  //   this.DIModel.inspector = this.export.inspector.name;
  //   this.DIModel.vesselAgent = this.export.vesselAgents.name;
  //   this.DIModel.comment = this.export.comment;
  //   this.DIModel.cargoNumber = this.export.cargoNumber;
  //   this.DIModel.productId = this.export.products.id;
  //   this.DIModel.terminalId = this.export.terminals.id;
  // }
  getNominationValues() {
    // console.log(this.export);
    this.NominationModel.letterDate = this.dateService.currentDateForDocument();
    this.NominationModel.dateRange = this.dateService.documentDateRange(this.export.dateRangeStart,this.export.dateRangeEnd);
    this.NominationModel.vesselName = this.export.vessel.name;
    this.NominationModel.ETA = this.dateService.documentDate(this.export.eta);
    this.NominationModel.volume = this.export.norminatedCargo;
    this.NominationModel.volumeMetricTons = this.export.norminatedCargo;
    this.NominationModel.terminal = this.export.terminals.name;
    this.NominationModel.DWT = this.export.vessel.dwt;
    this.NominationModel.NXPNumber = this.export.nxpNumber;
    this.NominationModel.destination = this.export.destinations.name;
    this.NominationModel.consignor = this.export.consignor.name;
    this.NominationModel.consignee = this.export.consignees.name;
    this.NominationModel.inspector = this.export.inspector.name;
    this.NominationModel.vesselAgent = this.export.vesselAgents.name;
    this.NominationModel.comment = this.export.comment;
    this.NominationModel.cargoNumber = this.export.cargoNumber;
    this.NominationModel.cargoType = this.export.cargoTypes['name'];
    this.NominationModel.flagOfVessel = this.export.vessel.flag;
    this.NominationModel.nxpNumber = this.export.nxpNumber;
    this.NominationModel.vesselIMO = this.export.vessel.imoNumber;
    this.NominationModel.productId = this.export.products.id;
    this.NominationModel.terminalId = this.export.terminals.id;
    console.log(this.NominationModel);
  }

  downloadFile() {
    this.fileToDownload.path = this.filePathToDownload;
    // console.log(this.filePathToDownload);
    this.documentService.downloadFileWithPath(this.fileToDownload).subscribe(
      data => {
        console.log(data);
        saveAs(data, 'Download.docx');
        this.alertify.success('Download Successful');
      }, error => {
        this.alertify.error('Unable to load files');
      }
    );
  }
  setCurrentTab(id: number){
    this.currentTab = id;
    console.log(this.currentTab);
  }
}
