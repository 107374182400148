import { Component, OnInit, TemplateRef } from '@angular/core';
import { Export } from '../../_models/export';
import { ExportService } from '../../_services/export.service';
import { AlertifyService } from '../../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../../_services/auth.service';
import { DocumentService } from '../../_services/document.service';
import * as saveAs from 'file-saver';
import { DateService } from 'src/app/_services/date.service';
import { ExcessWater } from '../../_models/excesswater';

@Component({
  selector: 'app-excess-water-detail-update',
  templateUrl: './excess-water-detail-update.component.html',
  styleUrls: ['./excess-water-detail-update.component.css']
})
export class ExcessWaterDetailUpdateComponent implements OnInit {

  export: Export;
  modalRef: BsModalRef;
  exportToDuplicateID: number;
  //DIModel: any = {};
  NominationModel: any = {};
  fileToDownload: any = {};
  public hasFile = false;
  public filePathToDownload: string;
  public currentTab = 0;
  excessWaterId: number;
  exportId: number;
  constructor(private exportService: ExportService,
    private alertify: AlertifyService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private authService: AuthService,
    private router: Router,
    private documentService: DocumentService,
    private dateService: DateService,
    private nav: ActivatedRoute) { }

  ngOnInit() {
    this.excessWaterId = this.nav.params["value"].id;
    this.exportId = this.nav.params["value"].exportId;
    this.loadExcessWaterDetail();
    this.loadExport(this.exportId);
  }

  loadExcessWaterDetail() {
    this.exportService.getExcessWaterDetail(this.excessWaterId).subscribe(
      (excesswater: ExcessWater) => {
        this.transformExcessWaterDetail(excesswater);
      },
      error => {
        this.alertify.error(error);
      }
    );
  }

  transformExcessWaterDetail(excessWater: any) {
    this.claimReceived = excessWater?.claimReceived;
    this.vesselExperienceFactor = excessWater?.vesselExperienceFactor;
    this.freeWaterMeasuredOnArrival = excessWater?.freeWaterMeasuredOnArrival;
    this.terminalBsmw = excessWater?.terminalBsmw;
    this.sedimentWaterArrival = excessWater?.sedimentWaterArrival;
    this.totalExcessFreeWater = excessWater?.totalExcessFreeWater;
    this.exportId = excessWater?.exportId;
    this.id = excessWater?.id;
    this.dateCreated = excessWater?.dateCreated;
    this.dateUpdated = excessWater?.dateUpdated;
    this.unitSalesValue = excessWater?.unitSalesValue;
    this.totalValueLoss = excessWater?.totalValueLoss;
  }

  loadExport(exportId: any) {
    this.exportService.getExport(exportId).subscribe((exports: Export) => {
      this.export = exports;
    }, error => {
      this.alertify.error(error);
    });
  }

  duplicateExport() {
    console.log(this.exportToDuplicateID);
    this.exportService.duplicateExportWithID(this.exportToDuplicateID, this.authService.decodedToken.nameid).subscribe(
      (exports: Export) => {
        this.modalRef.hide();
        this.alertify.success('Export Successfully Duplicated');
        this.router.navigate(['/dash-board']);
      }, error => {
        this.alertify.error('Problem Duplicating Export');
      }
    );
  }

  generateDocumentInstruction(lifterId: number) {
    this.getNominationValues();
    console.log('Generting Document....');

    this.documentService.generateDocumentInstructionStardeep(lifterId, this.NominationModel).subscribe(
      data => {
        console.log(data);
        saveAs(data, this.NominationModel.cargoNumber + ' DI.docx');
        this.alertify.success('Download Successful');
      }, error => {
        this.alertify.error('Unable to load files');
      }
    );
  }

  getNominationValues() {
    // console.log(this.export);
    this.NominationModel.letterDate = this.dateService.currentDateForDocument();
    this.NominationModel.dateRange = this.dateService.documentDateRange(this.export.dateRangeStart, this.export.dateRangeEnd);
    this.NominationModel.vesselName = this.export.vessel.name;
    this.NominationModel.ETA = this.dateService.documentDate(this.export.eta);
    this.NominationModel.volume = this.export.norminatedCargo;
    this.NominationModel.volumeMetricTons = this.export.norminatedCargo;
    this.NominationModel.terminal = this.export.terminals.name;
    this.NominationModel.DWT = this.export.vessel.dwt;
    this.NominationModel.NXPNumber = this.export.nxpNumber;
    this.NominationModel.destination = this.export.destinations.name;
    this.NominationModel.consignor = this.export.consignor.name;
    this.NominationModel.consignee = this.export.consignees.name;
    this.NominationModel.inspector = this.export.inspector.name;
    this.NominationModel.vesselAgent = this.export.vesselAgents.name;
    this.NominationModel.comment = this.export.comment;
    this.NominationModel.cargoNumber = this.export.cargoNumber;
    this.NominationModel.cargoType = this.export.cargoTypes['name'];
    this.NominationModel.flagOfVessel = this.export.vessel.flag;
    this.NominationModel.nxpNumber = this.export.nxpNumber;
    this.NominationModel.vesselIMO = this.export.vessel.imoNumber;
    this.NominationModel.productId = this.export.products.id;
    this.NominationModel.terminalId = this.export.terminals.id;
    console.log(this.NominationModel);
  }

  downloadFile() {
    this.fileToDownload.path = this.filePathToDownload;
    // console.log(this.filePathToDownload);
    this.documentService.downloadFileWithPath(this.fileToDownload).subscribe(
      data => {
        console.log(data);
        saveAs(data, 'Download.docx');
        this.alertify.success('Download Successful');
      }, error => {
        this.alertify.error('Unable to load files');
      }
    );
  }
  setCurrentTab(id: number) {
    this.currentTab = id;
    console.log(this.currentTab);
  }

  //Restrict input to numbers and a single decimal points 
  decimalKeyPressValidations(event: KeyboardEvent) {
    const allowedKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
    //prevent more then one decimal point
    const inputElement = event.target as HTMLInputElement;
    if (event.key === '.' && inputElement.value.includes('.')) {
      event.preventDefault();
    }
  }

  decimalInputValidations(event: any, modelInputType: any) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    //check if the num has more than 3 decimal places
    const decimalMatch = value.match(/^\d+(\.\d{0,3})?/);
    if (decimalMatch) {
      switch (modelInputType) {
        case "ClaimReceived":
          inputElement.value = decimalMatch[0];
          this.claimReceived = parseFloat(inputElement.value);
          break;
        case "FreeWaterMeasuredOnArrival":
          inputElement.value = decimalMatch[0];
          this.freeWaterMeasuredOnArrival = parseFloat(inputElement.value);
          break;
        case "TerminalBsmw":
          inputElement.value = decimalMatch[0];
          this.terminalBsmw = parseFloat(inputElement.value);
          break;
        case "SedimentWaterArrival":
          inputElement.value = decimalMatch[0];
          this.sedimentWaterArrival = parseFloat(inputElement.value);
          break;
      }
    }
  }

  calculateExcessFreeWaterValue() {
    if (!this.isNullOrEmptyOrUndefined(this.freeWaterMeasuredOnArrival) || !this.isNullOrEmptyOrUndefined(this.terminalBsmw)
      || !this.isNullOrEmptyOrUndefined(this.sedimentWaterArrival)) {
      return this.totalExcessFreeWater = (((!this.isNullOrEmptyOrUndefined(this.terminalBsmw) ? (parseFloat(this.terminalBsmw.toString())) : 0)
        + (!this.isNullOrEmptyOrUndefined(this.sedimentWaterArrival) ? (parseFloat(this.sedimentWaterArrival.toString())) : 0))
        - (!this.isNullOrEmptyOrUndefined(this.freeWaterMeasuredOnArrival) ? (parseFloat(this.freeWaterMeasuredOnArrival.toString())) : 0));
    }
  }

  isNullOrEmptyOrUndefined(value: any) {
    if (value == null || value == '' || value == undefined || value == 0) {
      return true;
    } else {
      return false;
    }
  }

  claimReceived: number;
  vesselExperienceFactor: number;
  freeWaterMeasuredOnArrival: number;
  terminalBsmw: number;
  sedimentWaterArrival: number;
  totalExcessFreeWater: number;
  dateCreated: Date;
  dateUpdated: Date;
  id: number;
  unitSalesValue: number;
  totalValueLoss: number;
  updateExcessWater() {
    this.transformUpdatedata();
    if (this.isNullOrEmptyOrUndefined(this.totalExcessFreeWater)) {
      this.alertify.error('Error Calculate the Excess Water Volume (in barrels or metric tons) Can not be Zero.');
      return;
    }
    this.exportService
      .updateExcessWater(this.id, this.modelForUpdateData)
      .subscribe(
        next => {
          this.alertify.success('ExcessWater Details Successfully Updated');
          this.router.navigate(['excess-water-detail', this.id, this.exportId]);
        },
        error => {
          this.alertify.error(error);
        }
      );
    this.router.navigate(['/export-water-detail/', this.id, this.exportId]);
  }

  modelForUpdateData: any = {};
  transformUpdatedata() {
    this.modelForUpdateData.claimReceived = this.claimReceived;
    this.modelForUpdateData.vesselExperienceFactor = this.vesselExperienceFactor;
    this.modelForUpdateData.freeWaterMeasuredOnArrival = this.freeWaterMeasuredOnArrival;
    this.modelForUpdateData.terminalBsmw = this.terminalBsmw;
    this.modelForUpdateData.sedimentWaterArrival = this.sedimentWaterArrival;
    this.modelForUpdateData.totalExcessFreeWater = this.totalExcessFreeWater;
    this.modelForUpdateData.exportId = this.exportId;
    this.modelForUpdateData.id = this.id;
    this.modelForUpdateData.dateCreated = this.dateCreated;
    this.modelForUpdateData.dateUpdated = new Date();
    this.modelForUpdateData.unitSalesValue = this.unitSalesValue;
    this.modelForUpdateData.totalValueLoss = this.totalValueLoss;
  }

  calTotalExcessFreeWaterLossValue() {
    this.totalValueLoss = 0;
    if (!this.isNullOrEmptyOrUndefined(this.unitSalesValue) || !this.isNullOrEmptyOrUndefined(this.totalExcessFreeWater)) {
      return this.totalValueLoss = (((!this.isNullOrEmptyOrUndefined(this.unitSalesValue) ? (parseFloat(this.unitSalesValue.toString())) : 0)
        * (!this.isNullOrEmptyOrUndefined(this.totalExcessFreeWater) ? (parseFloat(this.totalExcessFreeWater.toString())) : 0)));
    }
  }
}
